<template>
  <div>
    <teleport v-if="isInExecuteStep" to="#toolbar">
      <Tabs
        v-model:tab="activeStep"
        :tabs="tabs"
        :style="2"
        @update:tab="toggleStep"
      >
        <template #[lockPlanTabSlotName]>
          <div
            v-if="
              !review.entity.value?.isLocked &&
              review.entity.value.plan?.lockState === ReviewLockState.LOCKED
            "
            class="flex items-center gap-2 bg-red-600 text-white px-3 py-[10px] text-xs rounded ml-6 font-medium"
          >
            <LockClosedIcon class="w-4 h-auto" /> Lock review
          </div>
          <div
            v-else
            class="flex items-center gap-2 bg-green-600 text-white px-3 py-[10px] text-xs rounded ml-6 font-medium"
          >
            <LockOpenIcon class="w-4 h-auto" /> Unlock review
          </div>
        </template>
      </Tabs>

      <div
        v-if="warningMessage"
        class="bg-yellow-100 text-yellow-700 px-4 py-2 flex items-center gap-2 rounded-lg mx-6"
      >
        <InformationCircleIcon class="w-5 h-5" />
        {{ warningMessage }}
      </div>
    </teleport>

    <div class="flex">
      <div
        v-if="review.entity.value?.studies?.length > 0"
        class="inline-grid pr-4 pb-4"
        :style="gridStyle"
      >
        <template v-if="shouldShowHeaders">
          <div
            class="border-t-[16px] border-t-white bg-white border-l-[16px] border-l-white sticky top-0 left-0 z-[51]"
          >
            <div
              class="rounded-tl-md bg-primary justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
            >
              <div class="flex items-center gap-2">
                <Sort />
                <div>
                  <button
                    class="text-white block"
                    @click="isMetadataExpanded = !isMetadataExpanded"
                  >
                    <ArrowsPointingInIcon
                      v-if="isMetadataExpanded"
                      class="h-5"
                    />
                    <ArrowsPointingOutIcon v-else class="h-5" />
                  </button>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <button
                  v-if="review.displayOptions.isScreeningStageActive.value"
                  v-tooltip="'Toggle showing only favourites'"
                  class="block"
                  @click.stop="toggleFavoriteFilter"
                >
                  <StarIcon
                    class="w-6 h-6 stroke-2"
                    :class="[
                      showOnlyFavourites
                        ? 'fill-amber-400 stroke-amber-400'
                        : 'fill-transparent stroke-[#A8B0B9] hover:fill-amber-400/80 hover:stroke-amber-400/80',
                    ]"
                  />
                </button>
                <SearchAndHighlight />
              </div>
            </div>
          </div>
          <template v-if="review.displayOptions.isScreeningStageActive.value">
            <div
              class="border-t-[16px] box-border border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary rounded-tr-md border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium justify-between"
              >
                Screening
                <button class="relative" @click="toggleChart">
                  <div
                    v-if="review.filtering.hasFiltersApplied.value"
                    class="absolute"
                  >
                    <span class="relative flex h-2 w-2">
                      <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"
                      ></span>
                      <span
                        class="relative inline-flex rounded-full h-2 w-2 bg-red-500"
                      ></span>
                    </span>
                  </div>
                  <ChartPieIcon class="w-5 h-auto" />
                </button>
              </div>
              <div
                class="bg-white -top-4 -right-10 w-10 h-[calc(100%+16px)] absolute"
              ></div>
            </div>
          </template>
          <template v-if="review.displayOptions.isStudyDesignStageActive.value">
            <div
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                Study characteristis
              </div>
            </div>
          </template>
          <template v-if="review.displayOptions.isAppraisalStageActive.value">
            <div
              v-if="
                review.entity.value?.plan?.appraisalPlan
                  .isImdrfMdce2019Applicable
              "
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                IMDRF MDCE 2019
              </div>
            </div>
            <div
              v-if="
                review.entity.value?.plan?.appraisalPlan
                  .isOxfordLevelOfEvidenceApplicable
              "
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                Oxford LOE
              </div>
            </div>
            <div
              v-if="
                review.entity.value?.plan?.appraisalPlan
                  .isPeerReviewStatusApplicable
              "
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                class="bg-primary rounded-tr-md border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
              >
                Peer review status
              </div>
            </div>
          </template>
          <template v-if="review.displayOptions.isSynthesisStageActive.value">
            <div
              v-for="(attributeStructure, attributeStructureIndex) in review
                .currentAttributesStructure.value"
              :key="attributeStructure.id"
              class="border-t-[16px] border-t-white sticky top-0 left-0 z-40"
            >
              <div
                v-tooltip="
                  attributeStructure.label +
                  '<br/>' +
                  attributeStructure.question
                "
                class="bg-primary justify-between border-r border-[#D9E4EA] text-white flex p-3.5 items-center h-full font-medium"
                :class="{
                  'rounded-tr-md':
                    attributeStructureIndex ===
                    review.currentAttributesStructure.value.length - 1,
                }"
              >
                <div class="truncate">{{ attributeStructure.label }}</div>
              </div>
            </div>
          </template>
        </template>
        <StudyCard
          v-for="study in review.filteredStudies.value"
          :key="study.id"
          :study="study"
          :screening-stage="review.displayOptions.isScreeningStageActive.value"
          :synthesis-stage="review.displayOptions.isSynthesisStageActive.value"
          :study-design-stage="
            review.displayOptions.isStudyDesignStageActive.value
          "
          :is-metadata-expanded="isMetadataExpanded"
        />
      </div>
      <div v-else class="p-6 inline-flex flex-col">This review is empty</div>
      <ScreeningChart
        :model-value="shouldShowChart"
        @update:model-value="setChartShown"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import StudyCard from '@app/views/Review/Execute/Studies/Card/StudyCard.vue'
import {
  computed,
  onActivated,
  onDeactivated,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'
import Sort from '@app/views/Review/Execute/Sort/Sort.vue'
import SearchAndHighlight from './SearchAndFilters/SearchAndHighlight.vue'
import {
  ArrowsPointingOutIcon,
  ChartPieIcon,
  StarIcon,
  ArrowsPointingInIcon,
} from '@heroicons/vue/24/outline'
import { SnackbarState, StudyState } from '@app/types'
import ScreeningChart from './ScreeningChart.vue'
import Tabs from '@app/components/Global/Tabs/Tabs.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { DevToolboxKey } from '@app/injectionKeys'
import {
  InformationCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/vue/24/solid'
import { ExecuteStep } from '../review.types'

const warningMessage = computed(() => {
  if (review.isArchived.value)
    return "The review is archived, you can't make changes."
  if (shouldShowNoArticlesIncludedMessage.value)
    return 'No articles have been included.'
  if (
    review.isLocked.value ||
    review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED
  )
    return 'The review is locked, unlock it to make changes.'
  return ''
})

const review = injectStrict(ReviewKey)
const { isCuttingEdge } = injectStrict(DevToolboxKey)
const activeStep = ref<ExecuteStep>(ExecuteStep.screening)

const lockPlanTabSlotName = `tab-${ExecuteStep.reviewLock}`
const tabs = [
  {
    number: ExecuteStep.screening,
    title: 'Screening',
  },

  ...(isCuttingEdge
    ? [
        {
          number: ExecuteStep.studyDesign,
          title: 'Study design',
        },
      ]
    : []),

  ...(review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable ||
  review.entity.value?.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable ||
  review.entity.value?.plan?.appraisalPlan.isPeerReviewStatusApplicable
    ? [
        {
          number: ExecuteStep.appraisal,
          title: 'Appraisal',
        },
      ]
    : []),
  {
    number: ExecuteStep.synthesis,
    title: 'Extraction',
  },
  {
    number: ExecuteStep.reviewLock,
    isDisabled: review.isArchived.value,
    handler: async () => {
      await toggleReviewLock()
    },
  },
]

const loading = useLoading()
const snackbar = useSnackbar()

watch(activeStep, (v) => {
  toggleStep(v)
})

onUnmounted(() => {
  toggleStep(ExecuteStep.screening)
})

function toggleStep(step: ExecuteStep) {
  switch (step) {
    case ExecuteStep.screening:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.screening)
      review.filtering.resetFilters()
      break
    case ExecuteStep.appraisal:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.appraisal)
      review.filtering.filterStudiesByState(StudyState.INCLUDED)
      break
    case ExecuteStep.synthesis:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.synthesis)
      review.filtering.filterStudiesByState(StudyState.INCLUDED)
      break
    case ExecuteStep.studyDesign:
      review.displayOptions.setActiveExecuteStep(ExecuteStep.studyDesign)
      review.filtering.filterStudiesByState(StudyState.INCLUDED)
      break
  }
}

const showOnlyFavourites = computed(() => {
  const filters = review.filtering.filters.value ?? {}
  return Object.keys(filters).every(
    (k) =>
      (k === StudyState.FAVOURITE && filters[k] === true) ||
      (k !== StudyState.FAVOURITE && filters[k as StudyState] === false),
  )
})
function toggleFavoriteFilter() {
  if (!showOnlyFavourites.value)
    review.filtering.filterStudiesByState(StudyState.FAVOURITE)
  else review.filtering.resetFilters()
}

const isMetadataExpanded = ref(true)

const gridStyle = computed(() => {
  let style = `grid-template-columns:${isMetadataExpanded.value ? 600 : 400}px`
  if (review.displayOptions.isScreeningStageActive.value)
    style += ' max-content'
  if (review.displayOptions.isStudyDesignStageActive.value) style += ' 700px'
  if (review.displayOptions.isAppraisalStageActive.value) {
    if (review.entity.value?.plan?.appraisalPlan.isImdrfMdce2019Applicable) {
      style += ' max-content'
    }
    if (
      review.entity.value?.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
    ) {
      style += ' max-content'
    }
    if (review.entity.value?.plan?.appraisalPlan.isPeerReviewStatusApplicable) {
      style += ' max-content'
    }
  }

  if (review.displayOptions.isSynthesisStageActive.value)
    review.currentAttributesStructure.value.forEach(() => {
      style += ' 400px'
    })
  return style
})

const isChartShown = ref(true)

const shouldShowChart = computed(() => {
  return (
    isChartShown.value &&
    review.displayOptions.isScreeningStageActive.value &&
    review.entity.value?.studies?.length > 0 &&
    isInExecuteStep.value
  )
})

function toggleChart() {
  isChartShown.value = !isChartShown.value
}

function setChartShown(value: boolean) {
  isChartShown.value = value
}

const shouldShowHeaders = computed(() => {
  return (
    isScreeningStageActiveWithStudies() ||
    isAppraisalStageActiveWithIncludedStudies() ||
    isSynthesisStageActiveWithIncludedStudies() ||
    isStudyDesignStageActiveWithIncludedStudies()
  )
})

function isAppraisalStageActiveWithIncludedStudies() {
  return (
    review.displayOptions.isAppraisalStageActive.value &&
    review.filteredStudies.value.length > 0
  )
}

function isSynthesisStageActiveWithIncludedStudies() {
  return (
    review.displayOptions.isSynthesisStageActive.value &&
    review.filteredStudies.value.length > 0
  )
}

function isStudyDesignStageActiveWithIncludedStudies() {
  return (
    review.displayOptions.isStudyDesignStageActive.value &&
    review.filteredStudies.value.length > 0
  )
}

function isScreeningStageActiveWithStudies() {
  return (
    review.displayOptions.isScreeningStageActive.value &&
    review.entity.value?.studies?.length > 0
  )
}

async function toggleReviewLock() {
  loading.start()
  try {
    if (review.entity.value.isLocked) {
      await unlockReview()
      if (review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED) {
        await lockPlan()
      }
    } else {
      if (review.entity.value.plan?.lockState === ReviewLockState.UNLOCKED) {
        await lockPlan()
      } else {
        await lockReview()
      }
    }
    await review.refresh()
  } finally {
    loading.stop()
  }
}

async function unlockReview() {
  try {
    await review.unlockReview(review.entity.value?.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while unlocking review, please try again.',
      )
    }
    throw e
  }
}

async function lockPlan() {
  try {
    await review.lockPlan(review.entity.value.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while locking review plan, please try again.',
      )
    }
    throw e
  }
}

async function lockReview() {
  try {
    await review.lockReview(review.entity.value?.id)
  } catch (e) {
    const error = e as HttpException
    if (
      error?.response?.data?.statusCode >= 400 &&
      error?.response?.data?.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'an error occured while locking review, please try again.',
      )
    }
    throw e
  }
}

const isInExecuteStep = ref(true)
onDeactivated(() => {
  isInExecuteStep.value = false
})

onActivated(() => {
  isInExecuteStep.value = true
})

const shouldShowNoArticlesIncludedMessage = computed(() => {
  return (
    review.filteredStudies.value.length <= 0 &&
    review.entity.value?.studies?.length > 0 &&
    (review.displayOptions.isAppraisalStageActive.value ||
      review.displayOptions.isSynthesisStageActive.value ||
      review.displayOptions.isStudyDesignStageActive.value)
  )
})
</script>
